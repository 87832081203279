<template>
        <div :class="`${($store.getters['display/getAside'])?'contents expanded':'contents'}`">

            <div class="container-fluid">
                <div class="social-dash-wrap">
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="breadcrumb-main">
                                <h4 class="text-capitalize breadcrumb-title">{{ t('welcome',{},{locale:getLocal}) }}</h4>
           
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <h4>{{ t('welcome',{},{locale:getLocal}) }}</h4>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
</template>

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
import { useI18n } from 'vue-i18n'
export default {
  name: 'Welcome',
  setup() {
      const {t, locale} = useI18n();
      return {t, locale};
  },
  data: function () {
    return {
        listData:[],
      }
    },
  mounted() {
      this.$store.dispatch('display/SET_pageName',"Welcome")
  
  },
  methods: {

  },
  computed:{
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    }
  }
//   computed: {
  	
//     showtime(){
//         return lightFormat(new Date(2014, 1, 11), 'yyyy-MM-dd')
//     }
//   }
}

</script>
<style scoped lang="scss">
table {
  width: 100% ;
  border-collapse: collapse !important;
  
}
table, td, th {
  border: 1px solid #F1F2F6;
  // border: 2px #F1F2F6 solid !important;
  text-align: center;
}
td{
  color: #5A5F7D !important;
  padding: 5px 15px ;
  font-size: 0.9rem !important;
}
.weather{
  padding: 7px !important;
  width: 40px;
}
.td-red{
  color: #FF4D4F !important;
  background-color: #FDEEEE;
}
.td-green{
  color: #20C997 !important;
}
.btn-select{
  background: rgb(47,85,164);
  background: linear-gradient(180deg, rgba(47,85,164,1) 0%, rgba(53,43,108,1) 100%);
  color: white;
  // border-radius: 8px;
  width: 100%;
  height: 100%;
}
</style>